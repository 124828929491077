import { APP_INITIALIZER, NgModule } from '@angular/core';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './auth/auth.module';
import { HttpLoaderFactory, SharedModule } from './shared/shared.module';
import { SessionStorageService } from './shared/services/session-storage.service';
import { CustomToastService } from './shared/services/custom-toast.service';
import { HeaderDetailsService } from './shared/services/header-details.service';
import { BreadCrumbService } from './shared/services/bread-crumb.service';
import { UserService } from './shared/user.service';
import { dataDogInitialize } from './utils/datadogRumInitialization';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    AuthModule.forRoot(),
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    TranslateService,
    SessionStorageService,
    UserService,
    CustomToastService,
    HeaderDetailsService,
    BreadCrumbService,
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: dataDogInitialize,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
