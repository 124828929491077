import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { Observable, tap, from } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserAuthenticationService } from './user-authentication.service';

export const UnAuthenticatedGuard: CanActivateFn = (): Observable<boolean> => {
  const authenticationService: UserAuthenticationService = inject(
    UserAuthenticationService,
  );
  const router: Router = inject(Router);

  return from(authenticationService.isAuthenticated()).pipe(
    tap((isAuthenticated: boolean): void => {
      if (isAuthenticated) {
        router.navigate(['']);
      }
    }),
    map((isAuthenticated: boolean) => !isAuthenticated),
  );
};
