import { Component } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { Hub } from 'aws-amplify/utils';
import { distinctUntilChanged, filter, tap } from 'rxjs/operators';
// eslint-disable-next-line import/no-extraneous-dependencies
import { datadogRum } from '@datadog/browser-rum';
import * as FullStory from '@fullstory/browser';
import {
  ErrorCodes,
  ErrorMessages,
  SessionStorageKey,
  SignInEvents,
} from './shared/constants/constants';
import { SessionStorageService } from './shared/services/session-storage.service';
import { CustomToastService } from './shared/services/custom-toast.service';
import { UserAuthenticationService } from './auth/services/user-authentication.service';
import { environment } from '../environments/environment';
import { UserService } from './shared/user.service';
import { FeatureFlagService } from './shared/types/feature-flag.service.interface';

@Component({
  selector: 'rwa-app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  title = 'rewaa-admin';

  async handleSignInEvent(event: string): Promise<void> {
    if (
      event === SignInEvents.SignIn ||
      event === SignInEvents.SignInWithRedirect ||
      event === SignInEvents.SignInWithRedirectFailure ||
      event === SignInEvents.SignedIn
    ) {
      this.sessionStorageService.removeItem(SessionStorageKey.LoginInProcess);
      if (event === SignInEvents.SignInWithRedirect) {
        const user = await this.userAuthenticationService.fetchUserAttributes();
        this.userService.setUser(user);
      }
    }
  }

  handleDatadog(): void {
    if (environment.enableDatadog) {
      this.userService
        .getUser()
        .pipe(
          filter((user) => !!user),
          distinctUntilChanged(),
          tap((user) => {
            const datadogUser = {
              id: JSON.parse(user?.identities ?? '{}')?.[0]?.userId,
              email: user?.email,
            };
            datadogRum.setUser(datadogUser);
          }),
        )
        .subscribe();
    }
  }

  handleFullstory(): void {
    if (environment.enableFullStory) {
      this.userService
        .getUser()
        .pipe(
          filter((user) => !!user),
          distinctUntilChanged(),
          tap((user) => {
            if (user) {
              if (!FullStory.isInitialized()) {
                FullStory.init({
                  orgId: 'o-1EF6QP-na1',
                  devMode: !environment.enableFullStory,
                });
              }
              FullStory.identify(
                `${JSON.parse(user?.identities ?? '{}')?.[0]?.userId}`,
                {
                  companyType_str: 'rewaa-admin',
                  email_str: user.email,
                  ...user,
                },
              );
            }
          }),
        )
        .subscribe();
    }
  }

  constructor(
    private primengConfig: PrimeNGConfig,
    public translate: TranslateService,
    private customToastService: CustomToastService,
    private userService: UserService,
    // its being used to initialize the user inside the feature flag service
    private featureFlagService: FeatureFlagService,
    private userAuthenticationService: UserAuthenticationService,
    private sessionStorageService: SessionStorageService,
  ) {
    Hub.listen('auth', async (data) => {
      await this.handleSignInEvent(data.payload.event);
      if (data.payload.event === SignInEvents.SignInWithRedirectFailure) {
        // @ts-ignore
        const { error } = data.payload.data;
        const message = this.getErrorMessage(error?.message);
        if (message) {
          this.customToastService.error(this.translate.instant(message));
        }
      }
    });
    this.primengConfig.ripple = true;

    translate.addLangs(['en', 'ar']);
    translate.setDefaultLang('en');

    const browserLang = translate.getBrowserLang() ?? '';
    translate.use(browserLang.match(/en|fr/) ? browserLang : 'en');
    this.handleDatadog();
  }

  ngOnInit(): void {
    this.handleFullstory();
  }

  getErrorMessage = (message: string): string | undefined => {
    if (message.includes(ErrorCodes.AccountNotExists)) {
      return ErrorMessages.AccountNotExists;
    }
    if (message.includes(ErrorCodes.BadDomainName)) {
      return ErrorMessages.DomainMismatch;
    }
    return undefined;
  };
}
