import { Component } from '@angular/core';
import { UserAuthenticationService } from '../../services/user-authentication.service';
import { UserService } from '../../../shared/user.service';

@Component({
  selector: 'rwa-login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
})
export class LoginComponent {
  constructor(
    private userAuthenticationService: UserAuthenticationService,
    private userService: UserService,
  ) {}

  async signIn(): Promise<void> {
    try {
      await this.userAuthenticationService.signIn('Google');
    } catch (e) {
      console.log(e);
    }
  }

  async signOut(): Promise<void> {
    try {
      await this.userAuthenticationService.signOut();
      this.userService.clearUser();
    } catch (e) {
      console.log(e);
    }
  }
}
