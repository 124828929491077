// eslint-disable-next-line check-file/filename-naming-convention
import { datadogRum } from '@datadog/browser-rum';
import { environment } from '../../environments/environment';

export function dataDogInitialize() {
  return (): void => {
    try {
      if (environment.enableDatadog) {
        datadogRum.init({
          applicationId: environment.datadogApplicationId,
          clientToken: environment.datadogClientToken,
          // `site` refers to the Datadog site parameter of your organization
          // see https://docs.datadoghq.com/getting_started/site/
          site: 'datadoghq.com',
          service: 'rewaa-admin',
          env: environment.name,
          excludedActivityUrls: [
            // Google APIs
            /analytics\.google\.com/,
            /stats\.g\.doubleclick\.net/,
            /identitytoolkit\.googleapis\.com/,
            /www\.googletagmanager\.com/,
            // Firestore
            /firestore\.googleapis\.com/,
            // Intercom
            /intercom\.io/,
            // LaunchDarkly
            /launchdarkly\.com/,
            // Service Worker
            // /platform.rewaatech.com\/ngsw\.json/,
            // User Guiding
            /userguiding\.com/,
            // Full Story
            (url) => url.startsWith('https://rs.fullstory.com/'),
          ],
          sessionSampleRate: 100,
          sessionReplaySampleRate: 20,
          trackUserInteractions: true,
          trackResources: true,
          trackLongTasks: true,
          defaultPrivacyLevel: 'mask-user-input',
          version: environment.appVersion,
        });
      }
    } catch (e) {
      console.error(e);
    }
  };
}
