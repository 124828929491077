<div class="auth-page">
  <div class="auth-card">
    <div class="auth-logo">
      <img src="../../../../assets/images/admin-logo.png" alt="logo">
    </div>
    <div class="auth-title">{{ 'login.title' | translate }}</div>
    <div class="auth-trigger">
      <!-- Replace below button with google widget -->
      <p-button (click)="signIn()">
        {{ 'login.sign_in' | translate }}
      </p-button>
    </div>
  </div>
</div>
